import SearchFile from '@public/assets/icons/SearchFile.svg';
import Option from '@public/assets/icons/Option.svg';
import Request from '@public/assets/icons/Request.svg';
import CheckFile from '@public/assets/icons/CheckFile.svg';
import MaterialCheck from '@public/assets/icons/MaterialCheck.svg';
import BeforeCash from '@public/assets/icons/BeforeCash.svg';
import Team from '@public/assets/icons/Team.svg';
import RealStore from '@public/assets/icons/RealStore.svg';
import HamburgerMenu from '@public/assets/icons/HamburgerMenu.svg';
import Close from '@public/assets/icons/Close.svg';
import Cross from '@public/assets/icons/Cross.svg';
import Divider from '@public/assets/icons/Divider.svg';
import Store from '@public/assets/icons/Store.svg';
import Buying from '@public/assets/icons/Buying.svg';
import Cart from '@public/assets/icons/Cart.svg';
import Setting from '@public/assets/icons/Setting.svg';
import Office from '@public/assets/icons/Office.svg';
import AccountOut from '@public/assets/icons/AccountOut.svg';
import MeetballMenu from '@public/assets/icons/MeetballMenu.svg';
import User from '@public/assets/icons/User.svg';
import Language from '@public/assets/icons/Language.svg';
import Frame from '@public/assets/icons/Frame.svg';
import Project from '@public/assets/icons/Project.svg';
import Statistics from '@public/assets/icons/Statistics.svg';
import SignOut from '@public/assets/icons/SignOut.svg';
import Noti from '@public/assets/icons/Noti.svg';
import NotiNew from '@public/assets/icons/NotiNew.svg';
import NotiLightOn from '@public/assets/icons/NotiLightOn.svg';
import NotiLightOff from '@public/assets/icons/NotiLightOff.svg';
import NotiCircle from '@public/assets/icons/NotiCircle.svg';
import CheckCircle from '@public/assets/icons/CheckCircle.svg';
import CheckCircleBlue from '@public/assets/icons/CheckCircleBlue.svg';
import CheckCircleEmpty from '@public/assets/icons/CheckCircleEmpty.svg';
import ArrowRight from '@public/assets/icons/ArrowRight.svg';
import ArrowDown from '@public/assets/icons/ArrowDown.svg';
import ArrowUp from '@public/assets/icons/ArrowUp.svg';
import ArrowLeft from '@public/assets/icons/ArrowLeft.svg';
import ArrowRightDouble from '@public/assets/icons/ArrowRightDouble.svg';
import ArrowLeftDouble from '@public/assets/icons/ArrowLeftDouble.svg';
import CheckSquare from '@public/assets/icons/CheckSquare.svg';
import CheckSquareBlue from '@public/assets/icons/CheckSquareBlue.svg';
import CheckSquareEmpty from '@public/assets/icons/CheckSquareEmpty.svg';
import RadioCircle from '@public/assets/icons/RadioCircle.svg';
import RadioCircleBlack from '@public/assets/icons/RadioCircleBlack.svg';
import RadioCircleBlue from '@public/assets/icons/RadioCircleBlue.svg';
import RadioCircleLightBlue from '@public/assets/icons/RadioCircleLightBlue.svg';
import RadioCircleEmpty from '@public/assets/icons/RadioCircleEmpty.svg';
import UploadFile from '@public/assets/icons/UploadFile.svg';
import AlertCircle from '@public/assets/icons/AlertCircle.svg';
import SearchThings from '@public/assets/icons/SearchThings.svg';
import Pipe from '@public/assets/icons/Pipe.svg';
import Valve from '@public/assets/icons/Valve.svg';
import Flange from '@public/assets/icons/Flange.svg';
import Fitting from '@public/assets/icons/Fitting.svg';
import Etc from '@public/assets/icons/Etc.svg';
import Calendar from '@public/assets/icons/Calendar.svg';
import AlertTriangle from '@public/assets/icons/AlertTriangle.svg';
import Delete from '@public/assets/icons/Delete.svg';
import Edit from '@public/assets/icons/Edit.svg';
import DownloadCircle from '@public/assets/icons/DownloadCircle.svg';
import AlertStop from '@public/assets/icons/AlertStop.svg';
import AlertMore from '@public/assets/icons/AlertMore.svg';
import ProjectStatusREQ from '@public/assets/icons/ProjectStatusREQ.svg';
import ProjectStatusCOL from '@public/assets/icons/ProjectStatusCOL.svg';
import ProjectStatusCMP from '@public/assets/icons/ProjectStatusCMP.svg';
import ProjectStatusBID from '@public/assets/icons/ProjectStatusBID.svg';
import ProjectStatusORD from '@public/assets/icons/ProjectStatusORD.svg';
import ProjectStatusFIN from '@public/assets/icons/ProjectStatusFIN.svg';
import ProjectStautCLS from '@public/assets/icons/ProjectStautCLS.svg';
import ProjectStatusSellerCLS from '@public/assets/icons/ProjectStautSellerCLS.svg';
import ProjectStatusSellerREQ from '@public/assets/icons/ProjectStatusSellerREQ.svg';
import ProjectStatusSellerORD from '@public/assets/icons/ProjectStatusSellerORD.svg';
import ProjectDetail from '@public/assets/icons/ProjectDetail.svg';
import ProjectDetailBlue from '@public/assets/icons/ProjectDetailBlue.svg';
import Filter from '@public/assets/icons/Filter.svg';
import Add from '@public/assets/icons/Add.svg';
import AddPerson from '@public/assets/icons/AddPerson.svg';
import AddOffice from '@public/assets/icons/AddOffice.svg';
import RangeWave from '@public/assets/icons/RangeWave.svg';
import Indicator from '@public/assets/icons/Indicator.svg';
import Chat from '@public/assets/icons/Chat.svg';
import DeleteAll from '@public/assets/icons/DeleteAll.svg';
import LogoBlack from '@public/assets/icons/LogoBlack.svg';
import Click from '@public/assets/icons/Click.svg';
import TextCopy from '@public/assets/icons/textCopy.svg';
import Reset from '@public/assets/icons/Reset.svg';
import Slash from '@public/assets/icons/Slash.svg';
import AlertQuestion from '@public/assets/icons/AlertQuestion.svg';
import PolygonUp from '@public/assets/icons/PolygonUp.svg';
import PolygonDown from '@public/assets/icons/PolygonDown.svg';
import WishList from '@public/assets/icons/WishList.svg';
import CircleArrowRight from '@public/assets/icons/CircleArrowRight.svg';
import Excel from '@public/assets/icons/Excel.svg';
import TakeIn from '@public/assets/icons/TakeIn.svg';
import TakeOut from '@public/assets/icons/TakeOut.svg';
import StockHistory from '@public/assets/icons/StockHistory.svg';
import NoStockHistory from '@public/assets/icons/NoStockHistory.svg';
import OpenWarehouse from '@public/assets/icons/OpenWarehouse.svg';
import PrivateWarehouse from '@public/assets/icons/PrivateWarehouse.svg';
import NewItem from '@public/assets/icons/NewItem.svg';
import StockItem from '@public/assets/icons/StockItem.svg';
import Star from '@public/assets/icons/Star.svg';


export const icons = {
  'search-file': SearchFile,
  option: Option,
  request: Request,
  'check-file': CheckFile,
  'material-check': MaterialCheck,
  'before-cash': BeforeCash,
  team: Team,
  'real-store': RealStore,
  'hamburger-menu': HamburgerMenu,
  close: Close,
  cross: Cross,
  divider: Divider,
  store: Store,
  buying: Buying,
  cart: Cart,
  setting: Setting,
  office: Office,
  'account-out': AccountOut,
  'meetbal-menu': MeetballMenu,
  user: User,
  language: Language,
  frame: Frame,
  project: Project,
  statistics: Statistics,
  'sign-out': SignOut,
  noti: Noti,
  'noti-new': NotiNew,
  'noti-light-on': NotiLightOn,
  'noti-light-off': NotiLightOff,
  'noti-circle': NotiCircle,
  'check-circle': CheckCircle,
  'check-circle-blue': CheckCircleBlue,
  'check-circle-empty': CheckCircleEmpty,
  'arrow-right': ArrowRight,
  'arrow-down': ArrowDown,
  'arrow-up': ArrowUp,
  'arrow-left': ArrowLeft,
  'arrow-right-double': ArrowRightDouble,
  'arrow-left-double': ArrowLeftDouble,
  'check-square': CheckSquare,
  'check-square-blue': CheckSquareBlue,
  'check-square-empty': CheckSquareEmpty,
  'radio-circle': RadioCircle,
  'radio-circle-black': RadioCircleBlack,
  'radio-circle-blue': RadioCircleBlue,
  'radio-circle-light-blue': RadioCircleLightBlue,
  'radio-circle-empty': RadioCircleEmpty,
  'upload-file': UploadFile,
  'alert-circle': AlertCircle,
  'search-things': SearchThings,
  pipe: Pipe,
  valve: Valve,
  flange: Flange,
  fitting: Fitting,
  etc: Etc,
  calendar: Calendar,
  'alert-triangle': AlertTriangle,
  delete: Delete,
  edit: Edit,
  'download-circle': DownloadCircle,
  'alert-stop': AlertStop,
  'alert-more': AlertMore,
  'project-status-req': ProjectStatusREQ,
  'project-status-col': ProjectStatusCOL,
  'project-status-cmp': ProjectStatusCMP,
  'project-status-bid': ProjectStatusBID,
  'project-status-ord': ProjectStatusORD,
  'project-status-fin': ProjectStatusFIN,
  'project-status-cls': ProjectStautCLS,
  'project-status-seller-cls': ProjectStatusSellerCLS,
  'project-status-seller-req': ProjectStatusSellerREQ,
  'project-status-seller-ord': ProjectStatusSellerORD,
  'project-detail': ProjectDetail,
  'project-detail-blue': ProjectDetailBlue,
  filter: Filter,
  add: Add,
  'add-person': AddPerson,
  'add-office': AddOffice,
  'range-wave': RangeWave,
  indicator: Indicator,
  chat: Chat,
  'delete-all': DeleteAll,
  'logo-black': LogoBlack,
  click: Click,
  'text-copy': TextCopy,
  reset: Reset,
  slash: Slash,
  'alert-question': AlertQuestion,
  'polygon-up': PolygonUp,
  'polygon-down': PolygonDown,
  'wish-list': WishList,
  'circle-arrow-right': CircleArrowRight,
  excel: Excel,
  'take-in': TakeIn,
  'take-out': TakeOut,
  'stock-history': StockHistory,
  'no-stock-history': NoStockHistory,
  'open-warehouse': OpenWarehouse,
  'private-warehouse': PrivateWarehouse,
  'new-item': NewItem,
  'stock-item': StockItem,
  'star': Star,
};

export type IconName = keyof typeof icons;
